.footer {
  width: 100%;
  height: 500px;
  background-color: #f2f7ff;
  margin-top: 30px;
  display: flex;
}

.foot {
  width: 32%;
  height: 100%;
  margin-left: 12px;
}

.foot2 {
  width: 90%;
  height: auto;
  padding-bottom: 20px;
  margin-left: 05%;
  margin-top: 05%;
  border-radius: 16px;
  background-color: white;
  box-shadow: 2px 3px 5px -2px gray;
}

.foot2 h1 {
  text-align: center;
  font-size: 25px;
  color: #0f3957;
  font-weight: bold;
  padding-top: 20px;
}

.foot2 h2 {
  text-align: center;
  font-size: 22px;
  color: #5abbc7;
  font-weight: bold;
  margin-top: 20px;
}

.foot2 p {
  margin-left: 30px;
  margin-top: 20px;
}

.foot2 p i {
  color: #0f3957;
  font-size: 23px;
}

.foot2 p b {
  color: #333;
  font-size: 18px;
}


.foot h1 {
 margin-left: 70px;
  font-size: 25px;
  margin-top: 40px;
}

.foot h3 {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  text-decoration: none;
  color: black;
  text-align: justify;
  width: 80%;
  margin-left: 80px;
}

.foot i {
  color: purple;
  font-weight: bold;
}

.disclamer {
  width: 100%;
  height: 50px;

  background-color: #0f3957;
}

.disclamer h1 {
  text-align: center;
  color: white;
  font-size: 12px;
  padding-top: 16px;
}



@media screen and (min-width: 300px) and (max-width: 600px) {

  .footer {
    width: 100%;
    height: auto;
    background-color: #f2f7ff;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }

  .foot {
    width: 100%;
    height: 100%;
    margin-left: 0px;
  }

  .foot2 {
    width: 90%;
    height: auto;
    margin-left: 05%;
    margin-top: 05%;
    border-radius: 16px;
    background-color: white;
    padding-bottom: 15px;
  }

  .foot2 h1 {
    text-align: center;
    font-size: 25px;
    color: #0f3957;
    font-weight: bold;
    padding-top: 20px;
  }

  .foot2 h2 {
    text-align: center;
    font-size: 22px;
    color: #5abbc7;
    font-weight: bold;
    margin-top: 20px;
  }

  .foot2 p {
    margin-left: 30px;
    margin-top: 20px;
    color: black;
  }

  .foot2 p i {
    color: #0f3957;
    font-size: 26px;
  }

  .foot2 p b {
    color: #333;
    font-size: 20px;
  }


  .foot h1 {
  
    font-size: 25px;
    margin-top: 40px;
  }

  .foot h3 {
   margin-left: 80px;
    font-size: 16px;
    margin-top: 30px;
    text-decoration: none;
    color: black;
    text-align: justify;
    width: 80%;
    margin-left: 80px;
  }

  .disclamer {
    width: 100%;
    height: 60px;

    background-color: #0f3957;
  }

  .disclamer h1 {
    text-align: center;
    color: white;
    font-size: 15px;
    padding-top: 10px;
  }


}

@media screen and (min-width: 600px) and (max-width: 1200px) {

  .footer {
    width: 100%;
    height: 500px;
    background-color: #f2f7ff;
    margin-top: 30px;
    display: flex;
  }
  
  .foot {
    width: 32%;
    height: auto;
    margin-left: 12px;
  }
  
  .foot2 {
    width: 100%;
    height: auto;
    margin-left: 05%;
    margin-top: 05%;
    border-radius: 16px;
    background-color: white;
    box-shadow: 2px 3px 5px -2px gray;
    
  }
  
  .foot2 h1 {
    text-align: center;
    font-size: 25px;
    color: #0f3957;
    font-weight: bold;
    padding-top: 20px;
  }
  
  .foot2 h2 {
    text-align: center;
    font-size: 22px;
    color: #5abbc7;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .foot2 p {
    margin-left: 20px;
    margin-top: 10px;
    color: black;
  }
  
  .foot2 p i {
   
    font-size: 20px;
  }
  
  .foot2 p b {
    color: #333;
    font-size: 18px;
  }
  
  
  .foot h1 {
   margin-left: 70px;
    font-size: 25px;
    margin-top: 40px;
  }
  
  .foot h3 {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    text-decoration: none;
    color: black;
    text-align: justify;
    width: 80%;
    margin-left: 80px;
  }
  
  .foot i {
    color: purple;
    font-weight: bold;
  }
  
  .disclamer {
    width: 100%;
    height: 50px;
  
    background-color: #0f3957;
  }
  
  .disclamer h1 {
    text-align: center;
    color: white;
    font-size: 12px;
    padding-top: 16px;
  }
  
  


}









.social-media-icons {
  margin-top: 20px; /* Add some spacing above the icons */
  display: flex;
  justify-content: center; /* Center the icons horizontally */
  gap: 15px; /* Add space between the icons */
  margin-left: -40px;
}

.social-media-icons a {
  color: #2b6cb0; /* Default blue color for the icons */
  font-size: 24px; /* Icon size */
  text-decoration: none; /* Remove underline from the links */
  transition: color 0.3s ease; /* Smooth transition on hover */
}

.social-media-icons a:hover {
  color: #3182ce; /* Slightly darker blue on hover */
}

.social-media-icons a i {
  padding: 10px;
  border-radius: 50%; /* Make the icons circular */
  border: 1px solid #d3e0ea; /* Light border for the icons */
  background-color: #f0f9ff; /* Light background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for a 3D effect */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.social-media-icons a:hover i {
  background-color: #e6f7ff; /* Slightly different background color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}
