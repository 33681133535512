.Main {
  width: 100%;
  height: 100%;
  /* background-image: url('./img/background.jpeg');
  background-size: cover;
  background-repeat: no-repeat; */

  /* background: linear-gradient(to right, #9fc0d3, #e1b9f5, #f4e2f2); */
}




.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #41375c;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar.open {
  width: 250px;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 17px;
  color: white;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 25px;
  /* Make the toggle icon larger */
  cursor: pointer;
  
  font-weight: bold;
  border-radius: 10px;
  background-color: white;
  color: #ff007f;
  padding: 10px 15px;
  border: none;
  margin-left: 15px;
  margin-top: 10px;
  position: fixed;
  top: 10px;
  left: 10px;
  /* Adjust position if needed */
}

@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }

  .sidebar a {
    font-size: 18px;
  }
}





.section {
  width: 100%;
  height: 780px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #CBC3E3;

}

.section img {
  height: 300px;
  margin-left: 40%;
  margin-top: 20px;
}

.section h1 {
  text-align: center;
  font-size: 3.1rem;
  font-weight: bold;
  color: #41375c;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.section h2 {
  text-align: center;
  font-size: 1.7rem;
  font-weight: bold;
  color: #41375c;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.section p {
  text-align: center;
  margin-top: 10px;
  font-size: 21px;
  color: #000000;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.section h3 {
  text-align: center;
  font-size: 28px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
}

.section h4 {
  text-align: center;
  font-size: 20px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.section button {
  background-color: initial;
  color: initial;
  border: none;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 17px;
  margin-top: -55%;
  border-radius: 10px;
  margin-left: 80%;
  background-color: #000000;
  color: white;
  border: 1px solid black;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.section button:hover {
  background-color: #FF9933;
  /* Saffron color */
  color: white;
  /* Change text color on hover, optional */
}


.about {
  width: 100%;
  height: 750px;
  margin-left: 0%;
  margin-top: -40px;
  background-image: url('./img/background.jpeg');
  background-repeat: no-repeat;
  background-size: cover;



}

.about img {
  float: right;
  margin-right: 40px;
  width: 250px;
  padding-top: 20px;
}


.about h1 {
  margin-left: 80px;
  margin-top: 40px;
  font-family: 'Courier New', Courier, monospace;
  padding-top: 20px;
  color: #5a0721;
}

.about p {
  margin-left: 90px;
  margin-top: 45px;
  font-size: 20px;
  text-align: justify;
  width: 65%;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.about p i {
  color: #5a0721;
  font-size: 16px;
}


.service {
  width: 100%;
  height: 350px;
  display: flex;
  margin-top: 15px;
  background-color: white;
  justify-content: space-around;

}

.servi2 {
  position: relative;
  width: 25%;
  height: 100%;
  overflow: hidden;
}

.servi2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.servi2 h2 {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  /* Add a semi-transparent background */
  padding: 10px 20px;
  border-radius: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 40px;
}

.servi2:hover h2 {
  background-color: purple;
  /* Light pink on hover */
  color: white;
}

.servi2:hover {
  cursor: pointer;
}

.servi2:hover img {
  filter: brightness(70%);
  /* Dim the image when hovering */
}



/* ReviewSlider.css */

.testimonial {

  background-color: #d6b4fc;
  margin-top: 40px;
  padding-bottom: 20px;

}


.slider-container {
  width: 80%;
  margin: auto;
  padding: 20px 0;
}

.slider-header {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.slider-item {
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.slick-dots li button:before {
  color: #007bff;
}



/* BlogPostUpload.css */
.blog-post-upload-container {
  width: 40%;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}




.blog-post-display {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.blog-post-display:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}

.blog-post-display h2 {
  margin-top: 0;
  font-size: 1.5em;
  color: #333;
}

.blog-post-display p {
  color: #555;
  line-height: 1.6;
}

.blog-post-display img {
  width: 300px;
  height: 300px;
  border-radius: 8px;
  margin-top: 10px;
}

.blog-post-display button {
  background-color: #dc3545;
  margin-top: 10px;
}









.Heading {
  width: 100%;
  height: 200px;
  background-color: purple;
}

.Heading h1 {
  text-align: center;
  padding-top: 50px;
  color: white;
}

.Heading p {
  text-align: center;
  padding-top: 30px;
  font-size: 18px;
  color: white;
}

.calls {
  width: 100%;
  height: 150px;
  display: flex;
}

.calls a {
  margin-left: 200px;
  padding-top: 50px;
  text-decoration: none;
  color: #262626;
  font-size: 1.7rem;
  font-weight: bold;
}

.served {
  width: 95%;
  height: 550px;
  margin-left: 02.5%;
  display: flex;
  margin-top: 15px;
}

.served2 {
  width: 30%;
  height: 100%;
  margin-left: 30px;
}

.served2 img {
  width: 100%;
  height: 200px;
}

.served2 h1 {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}

.served2 p {
  text-align: justify;
  margin-top: 20px;
  font-size: 16px;
  width: 90%;
}




.contact-form-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.contact-form-container h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group input[type="text"],
.form-group input[type="date"],
.form-group input[type="file"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
  height: 100px;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 18px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}








.contact-list {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.contact-list h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.contact-item {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.contact-item h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #007bff;
}

.contact-item p {
  margin: 5px 0;
  font-size: 16px;
  color: #555;
}

.contact-item img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 4px;
}

button {
  display: block;
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #dc3545;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #c82333;
}







.whatsapp-button {
  position: fixed;
  bottom: 30px;
  right: 0px;
  z-index: 1000;

}

.whatsapp-button img {
  width: 100px;
  height: 100px;

}

.newcontact{
  width: 100%;
  height: 400px;
 
  display: flex;
}



.card {
  max-width: 300px;
  padding: 20px;
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.card h2 {
  margin-top: 0;
  font-size: 1.5em;
}

.card p {
  margin: 8px 0;
  line-height: 1.6;
}

.derivates{
  width: 85%;
  height:380px;
margin-top: 10px;
}

.derivates img {
  width: 100%;
  padding: 10px;
  height: 100%;
}