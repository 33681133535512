@media screen and (min-width: 300px) and (max-width: 600px) {

  .Main {
    width: 100%;
    height: 100%;
  }



  .sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: #41375c;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 22px;
    color: white;
    display: block;
    transition: 0.3s;
  }

  .sidebar a:hover {
    color: #f1f1f1;
  }

  .sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .openbtn {
    font-size: 30px;
    /* Make the toggle icon larger */
    cursor: pointer;
    font-weight: bold;
    padding: 10px 15px;

    border: none;
    margin-left: 5px;
    margin-top: 0px;
    position: fixed;
    top: 10px;
    left: 10px;
    /* Adjust position if needed */
  }


  .section {
    width: 100%;
    height: auto;
    padding-bottom: 15px;
  }

  .section img {
    height: 250px;
    margin-left: 20%;
    margin-top: 140px;
  }

  .section h1 {
    text-align: center;
    font-size: 2.4rem;
    font-weight: bold;
    color: #41375c;
  }

  .section h2 {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: #41375c;
  }

  .section p {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
  }

  .section button {
    background-color: initial;
    color: initial;
    border: none;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    margin-left: 20%;
    background-color: #262626;
    color: white;
    border: 1px solid black;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .section button:hover {
    background-color: #FF9933;
    /* Saffron color */
    color: white;
    /* Change text color on hover, optional */
  }


  .about {
    width: 95%;
    height: auto;
    margin-left: 02%;
    box-shadow: 2px 3px 5px -2px #5a0721;

  }

  .about img {
    float: right;
    margin-right: 20px;
    width: 300px;
    padding-top: 20px;
  }


  .about h1 {
    margin-left: 10px;
    margin-top: 60px;
    font-family: 'Courier New', Courier, monospace;
    padding-top: 20px;
    color: #5a0721;
  }

  .about p {
    margin-left: 10px;
    margin-top: 25px;
    font-size: 24px;
    text-align: justify;
    width: 90%;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  
  

  /* ReviewSlider.css */

  .testimonial {
    background-color: #faf0e6;
    margin-top: 40px;
    padding-bottom: 20px;
  }


  .slider-container {
    width: 80%;
    margin: auto;
    padding: 20px 0;
  }

  .slider-header {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }

  .slider-item {
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .slick-dots li button:before {
    color: #007bff;
  }

  .footer {
    width: 100%;
    height: auto;
    background-color: #262626;
    margin-top: 60px;
    display: flex;

  }

  .footer p {
    color: white;
    padding-top: 30px;
    margin-left: 05px;
    font-size: 13px;
  }


  .footer p a {
    color: white;
    margin-left: 30px;
  }

  /* BlogPostUpload.css */
  .blog-post-upload-container {
    width: 70%;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-top: 70px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .form-group textarea {
    height: 150px;
    resize: vertical;
  }

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }




  .blog-post-display {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  .blog-post-display:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }

  .blog-post-display h2 {
    margin-top: 0;
    font-size: 1.5em;
    color: #333;
  }

  .blog-post-display p {
    color: #555;
    line-height: 1.6;
  }

  .blog-post-display img {
    width: 300px;
    height: 300px;
    border-radius: 8px;
    margin-top: 10px;
  }

  .blog-post-display button {
    background-color: #dc3545;
    margin-top: 10px;
  }









  .Heading {
    width: 100%;
    height: 200px;
    background-color: purple;
  }

  .Heading h1 {
    text-align: center;
    padding-top: 70px;
    color: white;
    font-size: 22px;
  }

  .Heading p {
    text-align: center;
    padding-top: 20px;
    font-size: 14px;
    color: white;
  }

  .calls {
    width: 100%;
    height: 150px;
    display: flex;
  }

  .calls a {
    margin-left: 40px;
    padding-top: 50px;
    text-decoration: none;
    color: #262626;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .served {
    width: 95%;
    height: auto;
    margin-left: 02%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  .served2 {
    width: 100%;
    height: 100%;
    margin-left: 0px;
  }

  .served2 img {
    width: 100%;
    height: 200px;
  }

  .served2 h1 {
    text-align: center;
    font-size: 25px;
    margin-top: 20px;
  }

  .served2 p {
    text-align: justify;
    margin-top: 30px;
    width: 90%;
  }
.calls a {
  font-size: 18px;
}
  .newcontact{
    width: 100%;
    height: auto;
   
    display: flex;
    flex-direction: column;
  }
  
  
  
  .card {
    max-width: 300px;
    padding: 20px;
    margin: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .card h2 {
    margin-top: 0;
    font-size: 1.5em;
  }
  
  .card p {
    margin: 8px 0;
    line-height: 1.6;
  }
  
  .derivates{
    width: 100%;
    height: 100%;
   
  }
  
  .derivates img {
    width: 100%;
    padding: 10px;
  }


  .opration img{
    width: 100%;
  }

}

@media (max-width: 1024px) {
  .servi2 {
    width: 45%; /* Adjust width to 45% for tablets */
    height: 300px; /* Reduce height for tablets */
  }

  .servi2 h2 {
    font-size: 20px; /* Reduce font size for better readability */
  }
}

/* Mobile devices */
@media (max-width: 768px) {
  .service {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center the items */
  }

  .servi2 {
    width: 90%; /* Full width on mobile */
    height: 250px; /* Further reduce height for mobile */
  }

  .servi2 h2 {
    font-size: 18px; /* Adjust text size for mobile screens */
  }
}

/* Small Mobile devices */
@media (max-width: 480px) {
  .servi2 {
    width: 95%; /* Full width on smaller screens */
    height: 300px;
    margin-top: 20px;
  }

  .servi2 h2 {
    font-size: 12px; /* Smaller font for smaller screens */
  }
}


@media screen and (min-width: 601px) and (max-width: 1250px) {


  .Main {
    width: 1250px;
    height: 100%;
  }

  .sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: #41375c;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }

  .sidebar a:hover {
    color: #f1f1f1;
  }

  .sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .openbtn {
    font-size: 30px;
    /* Make the toggle icon larger */
    cursor: pointer;
    font-weight: bold;
    padding: 10px 15px;

    border: none;
    margin-left: 15px;
    margin-top: 10px;
    position: fixed;
    top: 10px;
    left: 10px;
    /* Adjust position if needed */
  }


  .section {
    width: 100%;
    height: 770px;
    background-repeat: no-repeat;
    background-size: cover;


  }

  .section img {
    height: 300px;
    margin-left: 40%;
    margin-top: 20px;
  }

  .section h1 {
    text-align: center;
    font-size: 3.1rem;
    font-weight: bold;
    color: #41375c;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .section h2 {
    text-align: center;
    font-size: 1.7rem;
    font-weight: bold;
    color: #41375c;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .section p {
    text-align: center;
    margin-top: 10px;
    font-size: 21px;
    color: #000000;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .section h3 {
    text-align: center;
    font-size: 28px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
  }

  .section h4 {
    text-align: center;
    font-size: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .section button {
    background-color: initial;
    color: initial;
    border: none;
    font-weight: bold;
    padding: 10px 20px;
    font-size: 17px;
    margin-top: -45%;
    border-radius: 10px;
    margin-left: 80%;
    background-color: #000000;
    color: white;
    border: 1px solid black;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .section button:hover {
    background-color: #FF9933;
    /* Saffron color */
    color: white;
    /* Change text color on hover, optional */
  }

  .about {
    width: 90%;
    height: 750px;
    margin-left: 05%;
    box-shadow: 2px 3px 5px -2px #5a0721;

  }

  .about img {
    float: right;
    margin-right: 80px;
    width: 250px;
    padding-top: 20px;
  }


  .about h1 {
    margin-left: 80px;
    margin-top: 40px;
    font-family: 'Courier New', Courier, monospace;
    padding-top: 20px;
    color: #5a0721;
  }

  .about p {
    margin-left: 80px;
    margin-top: 25px;
    font-size: 24px;

    font-family: Georgia, 'Times New Roman', Times, serif;
  }

 

  /* ReviewSlider.css */

  .testimonial {
    background-color: #faf0e6;
    margin-top: 40px;
    padding-bottom: 20px;
  }


  .slider-container {
    width: 80%;
    margin: auto;
    padding: 20px 0;
  }

  .slider-header {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }

  .slider-item {
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .slick-dots li button:before {
    color: #007bff;
  }

  .footer {
    width: 100%;
    height: 100px;
    background-color: #262626;
    margin-top: 60px;
    display: flex;
  }

  .footer p {
    color: white;
    padding-top: 30px;
    margin-left: 20px;
    font-size: 18px;
  }


  .footer p a {
    color: white;
    margin-left: 30px;
  }

  /* BlogPostUpload.css */
  .blog-post-upload-container {
    width: 40%;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .form-group textarea {
    height: 150px;
    resize: vertical;
  }

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }




  .blog-post-display {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  .blog-post-display:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }

  .blog-post-display h2 {
    margin-top: 0;
    font-size: 1.5em;
    color: #333;
  }

  .blog-post-display p {
    color: #555;
    line-height: 1.6;
  }

  .blog-post-display img {
    width: 300px;
    height: 300px;
    border-radius: 8px;
    margin-top: 10px;
  }

  .blog-post-display button {
    background-color: #dc3545;
    margin-top: 10px;
  }









  .Heading {
    width: 100%;
    height: 200px;
    background-color: purple;
  }

  .Heading h1 {
    text-align: center;
    padding-top: 50px;
    color: white;
  }

  .Heading p {
    text-align: center;
    padding-top: 30px;
    font-size: 18px;
    color: white;
  }

  .calls {
    width: 100%;
    height: 150px;
    display: flex;
  }

  .calls a {
    margin-left: 300px;
    padding-top: 50px;
    text-decoration: none;
    color: #262626;
    font-size: 2rem;
    font-weight: bold;
  }

  .served {
    width: 95%;
    height: 550px;
    margin-left: 02.5%;
    display: flex;
    margin-top: 15px;
  }
  
  .served2 {
    width: 30%;
    height: 100%;
    margin-left: 30px;
  }
  
  .served2 img {
    width: 100%;
    height: 200px;
  }
  
  .served2 h1 {
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
  }
  
  .served2 p {
    text-align: justify;
    margin-top: 20px;
    font-size: 16px;
    width: 90%;
  }
  


  .contact-form-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .contact-form-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }

  .form-group input[type="text"],
  .form-group input[type="date"],
  .form-group input[type="file"],
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .form-group textarea {
    resize: vertical;
    height: 100px;
  }

  button[type="submit"] {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  button[type="submit"]:hover {
    background-color: #0056b3;
  }








  .contact-list {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .contact-list h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }

  .contact-item {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #eee;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }

  .contact-item h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #007bff;
  }

  .contact-item p {
    margin: 5px 0;
    font-size: 16px;
    color: #555;
  }

  .contact-item img {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
    border-radius: 4px;
  }

  button {
    display: block;
    margin-top: 10px;
    padding: 10px 15px;
    font-size: 16px;
    color: #fff;
    background-color: #dc3545;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  button:hover {
    background-color: #c82333;
  }

  .newcontact{
    width: 100%;
    height: 400px;
   
    display: flex;
  }
  
  
  
  .card {
    max-width: 300px;
    padding: 20px;
    margin: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .card h2 {
    margin-top: 0;
    font-size: 1.5em;
  }
  
  .card p {
    margin: 8px 0;
    line-height: 1.6;
  }
  
  .derivates{
    width: 85%;
    height: 100%;
    
  }
  
  .derivates img {
    width: 100%;
  }




  

}