.mydoct {
    width: 100%;
    height: auto;
    background-color: #f5f7ff;

}

.Obstetrician {
    width: 90%;
    height: 140px;
    margin-left: 05%;

}

.Obstetrician h1 {
    margin-left: 30px;
    font-size: 25px;
    padding-top: 30px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
}

.Obstetrician p {
    margin-left: 30px;
    margin-top: 10px;
    text-align: justify;
    width: 80%;
}

.doctorabout {
    width: 90%;
    height: auto;
    margin-left: 05%;
    display: flex;
}

.digree1 {
    width: 50%;
    height: 100%;
    margin-left: 0px;
}


.digree1 h1 {
    margin-left: 30px;
    font-size: 22px;
    margin-top: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}



.digree1 h2 {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 20px;
    margin-left: 30px;
    margin-top: 15px;
    text-align: justify;
    width: 90%;

}

.digree1 h2 span {
    font-size: 16px;

}


.contexts {
    width: 100%;
    margin-left: 0%;
    height: 270px;
}

.contexts p {
    margin-left: 30px;
    font-size: 18px;
    margin-top: 15px;
    text-align: justify;
    width: 90%;
}

.custom-hr {
    width: 150px;
    margin-left: 25px;
    border: none; /* Remove default border */
    border-top: 4px solid purple; /* Dark purple */
    filter: brightness(1.5); /* Increase brightness */
}

.digree2 {
    width: 350px;
    height: 450px;
    margin-left: 90px;
    margin-top: 60px;
    background-image: url(./img/doctor.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px 50px 30px 5px;
    box-shadow: 10px 12px 02px #099aa8;
}
.digree2 img {
    width: 350px;
    height: 100%;
    margin-left: 20%;
    margin-top: 10%;
    border-radius: 15px 50px 30px 5px;
    box-shadow: 10px 12px 02px #099aa8;
    z-index: 999;
}

.doctorss{
    width: 300px;
    height: 80px;
    z-index: 999;
    background-color: white;
    margin-top: 370px;
    margin-left: -90px;
    border-radius: 16px;
    
}

.doctorss h4 {
    margin-left: 4.5%;
    font-size: 17px;
 padding: 07px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: purple;
}
.doctorss p {
    font-size: 10px;
   width: 90%;
    text-align: justify;
    margin-left: 07%;
    margin-top: -14px;
    color: #3498db;
}




@media screen and (min-width: 300px) and (max-width: 600px) {
    .mydoct {
        width: 100%;
        height: auto;
        background-color: #f5f7ff;

    }

    .Obstetrician {
        width: 90%;
        height: 250px;
        margin-left: 05%;

    }

    .Obstetrician h1 {
        margin-left: 30px;
        font-size: 25px;
        padding-top: 30px;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-weight: bold;
    }

    .Obstetrician p {
        margin-left: 30px;
        margin-top: 10px;
    }

    .doctorabout {
        width: 90%;
        height: auto;
        margin-left: 05%;
        flex-direction: column;
        display: flex;
    }

    
.digree1 {
    width: 100%;
    height: 100%;
    margin-left: 0px;
}


.digree1 h1 {
    margin-left: 30px;
    font-size: 22px;
    margin-top: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}



.digree1 h2 {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 20px;
    margin-left: 30px;
    margin-top: 15px;
    text-align: justify;
    width: 90%;

}

.digree1 h2 span {
    font-size: 16px;

}


.contexts {
    width: 100%;
    margin-left: 0%;
    height: 270px;
}

.contexts p {
    margin-left: 30px;
    font-size: 18px;
    margin-top: 15px;
    text-align: justify;
    width: 90%;
}

.custom-hr {
    width: 150px;
    margin-left: 25px;
    border: none; /* Remove default border */
    border-top: 4px solid purple; /* Dark purple */
    filter: brightness(1.5); /* Increase brightness */
}

.digree2 {
    width: 100%;
    height: 450px;
    margin-left: 0px;
    margin-top: 100px;
    background-image: url(./img/doctor.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px 50px 30px 5px;
    box-shadow: 10px 12px 02px #099aa8;
}
.digree2 img {
    width: 350px;
    height: 100%;
    margin-left: 20%;
    margin-top: 10%;
    border-radius: 15px 50px 30px 5px;
    box-shadow: 10px 12px 02px #099aa8;
    z-index: 999;
}

.doctorss{
    width: 90%;
    height: 80px;
    z-index: 999;
    background-color: white;
    margin-top: 390px;
    margin-left: 20px;
    border-radius: 16px;
    border: 1px solid lightgray;
}

.doctorss h4 {
    margin-left: 4.5%;
    font-size: 17px;
 padding: 07px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: purple;
}
.doctorss p {
    font-size: 10px;
   width: 90%;
    text-align: justify;
    margin-left: 07%;
    margin-top: -14px;
    color: #3498db;
}

    
}


@media screen and (min-width: 600px) and (max-width: 1200px) {
    .mydoct {
        width: 100%;
        height: auto;
        background-color: #f5f7ff;
    
    }
    
    .Obstetrician {
        width: 90%;
        height: 140px;
        margin-left: 05%;
    
    }
    
    .Obstetrician h1 {
        margin-left: 30px;
        font-size: 25px;
        padding-top: 30px;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-weight: bold;
    }
    
    .Obstetrician p {
        margin-left: 30px;
        margin-top: 10px;
        text-align: justify;
        width: 80%;
    }
    
    .doctorabout {
        width: 90%;
        height: auto;
        margin-left: 05%;
        display: flex;
    }
    
    .digree1 {
        width: 50%;
        height: 100%;
        margin-left: 0px;
    }
    
    
    .digree1 h1 {
        margin-left: 30px;
        font-size: 22px;
        margin-top: 20px;
        font-family: Georgia, 'Times New Roman', Times, serif;
    }
    
    
    
    .digree1 h2 {
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 20px;
        margin-left: 30px;
        margin-top: 15px;
        text-align: justify;
        width: 90%;
    
    }
    
    .digree1 h2 span {
        font-size: 16px;
    
    }
    
    
    .contexts {
        width: 100%;
        margin-left: 0%;
        height: 270px;
    }
    
    .contexts p {
        margin-left: 30px;
        font-size: 18px;
        margin-top: 15px;
        text-align: justify;
        width: 90%;
    }
    
    .custom-hr {
        width: 150px;
        margin-left: 25px;
        border: none; /* Remove default border */
        border-top: 4px solid purple; /* Dark purple */
        filter: brightness(1.5); /* Increase brightness */
    }
    
    .digree2 {
        width: 350px;
        height: 450px;
        margin-left: 90px;
        margin-top: 60px;
        background-image: url(./img/doctor.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px 50px 30px 5px;
        box-shadow: 10px 12px 02px #099aa8;
    }
    .digree2 img {
        width: 350px;
        height: 100%;
        margin-left: 20%;
        margin-top: 10%;
        border-radius: 15px 50px 30px 5px;
        box-shadow: 10px 12px 02px #099aa8;
        z-index: 999;
    }
    
    .doctorss{
        width: 300px;
        height: 80px;
        z-index: 999;
        background-color: white;
        margin-top: 370px;
        margin-left: -90px;
        border-radius: 16px;
        
    }
    
    .doctorss h4 {
        margin-left: 4.5%;
        font-size: 17px;
     padding: 07px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        color: purple;
    }
    .doctorss p {
        font-size: 10px;
       width: 90%;
        text-align: justify;
        margin-left: 07%;
        margin-top: -14px;
        color: #3498db;
    }
    
}



.publication-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.publication-title {
    font-size: 26px;
    color: purple;
    margin-bottom: 10px;
    border-bottom: 2px solid purple;
    padding-bottom: 5px;
    font-weight: bold;
    margin-top: 15px;
}

.publication-item {
    font-size: 16px;
    color: #34495e;
    margin: 10px 0;
}

.publication-item::before {
    content: "\2022"; /* Bullet point */
    color: #3498db;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
}

.publication-item:last-of-type {
    margin-bottom: 20px;
}

@media (max-width: 600px) {
    .publication-container {
        padding: 10px;
    }

    .publication-title {
        font-size: 20px;
    }

    .publication-item {
        font-size: 14px;
    }
}



.membership-list {
    max-width: 800px; /* Adjust as needed */
    margin: 0 auto;
    padding: 10px;
  }
  
  .membership-list p {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 20px; /* Adjust font size as needed */
    font-weight: bold;
  }
  
  .membership-logo {
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    margin-right: 10px; /* Space between image and text */
  }


  .gallery-container {
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the images */
  }
  
  .gallery-item {
    width: 30%; /* Default: Set width to 30% to fit 3 images in a row */
    margin: 1%; /* Add margin around each image */
    overflow: hidden; /* Ensure overflow is hidden for hover effect */
    transition: transform 0.3s; /* Smooth transition for hover effect */
  }
  
  .gallery-item img {
    width: 100%; /* Make the image responsive */
    height: auto; /* Set a fixed height */
    display: block; /* Remove inline-block spacing */
  }
  
  .gallery-item:hover {
    transform: scale(1.05); /* Scale the image on hover */
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 1024px) {
    .gallery-item {
      width: 45%; /* Two images in a row on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .gallery-item {
      width: 100%; /* One image in a row on small screens */
    }

   
  }
  

  .myheading {
    width: 100%;
    background-image: url(./img/galleryback.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px; /* Default height for larger screens */
}

/* Adjust height for smaller screens */
@media (max-width: 768px) {
    .myheading {
        height: 200px; /* Height for tablets and smaller devices */
    }
}

@media (max-width: 480px) {
    .myheading {
        height: 150px; /* Height for mobile devices */
        background-repeat: no-repeat;
    background-size: cover;
    }
}
