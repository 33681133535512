.laproscop-container {
    width: 100%;
    padding: 20px;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
  }
  
  .laproscop-header h2 {
    text-align: center;
    font-size: 28px;
    color: #333;
    margin-bottom: 30px;
  }
  
  .laproscop-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .laproscop-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .laproscop-text {
    max-width: 600px;
    line-height: 1.6;
    font-size: 18px;
    color: #444;
  }
  
  .laproscop-text ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .laproscop-text li {
    margin-bottom: 10px;
  }
  

  .highrisk-container {
    width: 100%;
    padding: 20px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
  }
  
  .highrisk-header h2 {
    text-align: center;
    font-size: 28px;
    color: #2c3e50;
    margin-bottom: 30px;
  }
  
  .highrisk-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .highrisk-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .highrisk-text {
    max-width: 600px;
    line-height: 1.6;
    font-size: 18px;
    color: #555;
  }
  
  .highrisk-text ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .highrisk-text li {
    margin-bottom: 10px;
  }
  


  .familyplan-container {
    width: 100%;
    padding: 20px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
  }
  
  .familyplan-header h2 {
    text-align: center;
    font-size: 28px;
    color: #2c3e50;
    margin-bottom: 30px;
  }
  
  .familyplan-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .familyplan-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .familyplan-text {
    max-width: 600px;
    line-height: 1.6;
    font-size: 18px;
    color: #555;
  }
  
  .familyplan-text ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .familyplan-text li {
    margin-bottom: 10px;
  }

  


  .vaginal-container {
    width: 100%;
    padding: 20px;
    background-color: #fafafa;
    font-family: Arial, sans-serif;
  }
  
  .vaginal-header h2 {
    text-align: center;
    font-size: 28px;
    color: #2c3e50;
    margin-bottom: 30px;
  }
  
  .vaginal-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .vaginal-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .vaginal-text {
    max-width: 600px;
    line-height: 1.6;
    font-size: 18px;
    color: #555;
  }
  
  .vaginal-text ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .vaginal-text li {
    margin-bottom: 10px;
  }
  

  .styled-hr {
    border: 0;
    height: 3px;
    background: linear-gradient(to right, #f00, white);
    margin: 20px 0;
    width: 40%;
    opacity: 0.8;
    text-align: center;
    margin-left: 30%;
    margin-top: 10px;
  }
  